.contact {
  display: inline-block;
  position: relative;
  padding: 6px;
  transition: 0.4s ease-in-out;
}
.contact:hover {
  padding: 6px 12px;
}

.expend-button {
  width: 12px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
}
.expend-button::before,
.expend-button::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 12px;
  border-style: solid;
  border-width: 2px;
  left: 0;
  transition: 0.4s ease-in-out;
}

.expend-button::before {
  bottom: 0;
}

.expend-button::after {
  top: 0;
}

.expend-left-button {
  left: 0;
}

.expend-left-button::before {
  border-top: 0;
  border-right: 0;
  border-image: linear-gradient(
      to bottom right,
      black,
      var(--main-active-color),
      var(--main-active-color),
      black
    )
    1%;
}
.expend-left-button::after {
  border-right: 0;
  border-bottom: 0;
  border-image: linear-gradient(
      to top right,
      black,
      var(--main-active-color),
      var(--main-active-color),
      black
    )
    1%;
}
.expend-right-button {
  right: 0;
}
.expend-right-button::before {
  border-top: 0;
  border-left: 0;
  border-image: linear-gradient(
      to bottom left,
      black,
      var(--main-active-color),
      var(--main-active-color),
      black
    )
    1%;
}
.expend-right-button::after {
  border-left: 0;
  border-bottom: 0;
  border-image: linear-gradient(
      to top left,
      black,
      var(--main-active-color),
      var(--main-active-color),
      black
    )
    1%;
}

.nav:hover .activeLine {
  transform: translate3d(0, 0, 0);
}

.nav .activeLine {
  background-color: #ff2937;
  height: 2px;
  width: 100%;
  transform: translate3d(-102%, 0, 0);
  transform-style: preserve-3d;
  transition: all 0.5s;
}

.activeRouterLine {
  background-color: #ff2937;
  height: 2px;
  width: 100%;
}
