.contact-title {
  position: absolute;
  z-index: 99;
  top: -30px;
  right: 100px;
  font-size: 100px;
  line-height: 1.5;
  text-align: right;
  letter-spacing: 0.04em;
  color: #fff;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.4);
}

.contact-border {
  position: relative;
  width: 1104px;
  height: 388px;
  background: linear-gradient(
    95.99deg,
    rgba(255, 41, 55, 0.7) -1.69%,
    rgba(255, 41, 55, 0) 51.27%,
    rgba(255, 41, 55, 0.5) 102.43%
  );
  clip-path: polygon(100px 0, 1104px 0, 1104px 286px, 1004px 388px, 0 388px, 0 102px);
}

.contact-content {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 1100px;
  height: 384px;
  background: linear-gradient(103.19deg, #2b080c 1.25%, #100e2b 117.23%);
  clip-path: polygon(100px 0, 1100px 0, 1100px 284px, 1000px 384px, 0 384px, 0 100px);
}
