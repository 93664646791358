@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-active-color: #ff2937;
}

body {
  width: 100vw;
  overflow-x: hidden;
  cursor: url('/overseacdn/passinArk/homepage/img/layouts/cursor.svg') 11 11, auto;
  color: #fff;
  background-color: black;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

/* https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s, font-size 600000s 0s;
}
/* 轮播文字 */
.short-carousel-front,
.carousel-front,
.carousel-reverse-front {
  display: inline-block;
  white-space: nowrap;
  padding-left: 0.3em;
  line-height: 1.5em;
  -webkit-animation: 20s rowfront linear infinite normal;
  animation: 20s rowfront linear infinite normal;
  will-change: transform;
  position: relative;
}

.short-carousel-back,
.carousel-back,
.carousel-reverse-back {
  display: inline-block;
  white-space: nowrap;
  padding-left: 0.3em;
  line-height: 1.5em;
  -webkit-animation: 20s rowback linear infinite normal;
  animation: 20s rowback linear infinite normal;
  will-change: transform;
  position: relative;
}

.short-carousel-front {
  -webkit-animation: 15s rowfront linear infinite normal;
  animation: 15s rowfront linear infinite normal;
}

.short-carousel-back {
  -webkit-animation: 15s rowback linear infinite normal;
  animation: 15s rowback linear infinite normal;
}

@keyframes rowfront {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes rowback {
  0% {
    -webkit-transform: translate3d(100%, -1.5em, 0);
    transform: translate3d(100%, -1.5em, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -1.5em, 0);
    transform: translate3d(0, -1.5em, 0);
  }
}

/* 反向轮播文字 */
.carousel-reverse-front {
  -webkit-animation: 15s row-reverse-front linear infinite normal;
  animation: 15s row-reverse-front linear infinite normal;
}

.carousel-reverse-back {
  -webkit-animation: 15s row-reverse-back linear infinite normal;
  animation: 15s row-reverse-back linear infinite normal;
}

@keyframes row-reverse-front {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes row-reverse-back {
  0% {
    -webkit-transform: translate3d(-100%, -1.5em, 0);
    transform: translate3d(-100%, -1.5em, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -1.5em, 0);
    transform: translate3d(0, -1.5em, 0);
  }
}
/* 旋转图形 */
.circle-container {
  transform: rotateZ(45deg);
  transform-style: preserve-3d;
  transition: all 0.5s linear;
}

.circle-container:hover {
  transform: rotateZ(-45deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.circle-around {
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.small-zoom {
  animation: small-scale 2s linear infinite;
}

.big-zoom {
  animation: big-scale 2s linear infinite;
}

@keyframes small-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes big-scale {
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(2.4);
  }
  100% {
    transform: scale(2);
  }
}
